import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Креативний Маркетинг
			</title>
			<meta name={"description"} content={"Креативний Маркетинг - професійне маркетингове агентство. Пропонуємо повний спектр послуг для розвитку вашого бізнесу: від стратегії до реалізації."} />
			<meta property={"og:title"} content={"Креативний Маркетинг"} />
			<meta property={"og:description"} content={"Креативний Маркетинг - професійне маркетингове агентство. Пропонуємо повний спектр послуг для розвитку вашого бізнесу: від стратегії до реалізації."} />
			<meta property={"og:image"} content={"https://uslinders.com/img/1.png"} />
			<link rel={"shortcut icon"} href={"https://uslinders.com/img/2628985.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uslinders.com/img/2628985.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uslinders.com/img/2628985.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="112px 0 112px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 50px/1.2 --fontFamily-sansVerdana"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Креативний Маркетинг
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Ласкаво просимо до маркетингового агентства Креативний Маркетинг. Ми спеціалізуємося на створенні унікальних маркетингових стратегій, які допомагають нашим клієнтам досягати своїх цілей. Наші експерти з маркетингу, реклами та комунікацій готові працювати з вами, щоб вивести ваш бізнес на новий рівень.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						target="_blank"
					>
						Зв'яжіться з нами
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uslinders.com/img/1.jpg"
					width="760px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box width="100%">
					<Image src="https://uslinders.com/img/2.jpg" width="100%" />
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box width="100%">
					<Image src="https://uslinders.com/img/3.jpg" display="block" width="100%" />
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box width="100%">
					<Image display="block" top="auto" src="https://uslinders.com/img/4.jpg" width="100%" />
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box width="100%">
					<Image display="block" top="auto" src="https://uslinders.com/img/5.jpg" width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Text as="h2" font="--headline2" margin="0 0 40px 0" text-align="center">
			Наші послуги
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 15px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Розробка маркетингових стратегій
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					У Креативний Маркетинг ми розуміємо, що успіх будь-якої компанії починається з правильної стратегії. Наші експерти аналізують ваш ринок, конкурентів та аудиторію, щоб створити унікальну маркетингову стратегію, яка допоможе вам досягти ваших бізнес-цілей.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Діджитал маркетинг
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Діджитал маркетинг є невід'ємною частиною сучасного бізнесу. Ми пропонуємо повний спектр послуг у сфері діджитал маркетингу, включаючи управління онлайн-рекламою, оптимізацію вебсайтів, електронний маркетинг та багато іншого.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Контент-маркетинг
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Контент є королем у світі маркетингу, і ми знаємо, як створити якісний та ефективний контент для вашого бізнесу. Наші контент-менеджери розробляють унікальний та цікавий контент, який привертає увагу вашої аудиторії, покращує SEO та підвищує лояльність клієнтів.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					SEO та SEM
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Пошукова оптимізація (SEO) та маркетинг у пошукових системах (SEM) є ключовими інструментами для підвищення видимості вашого сайту у пошукових системах. Наші фахівці з SEO та SEM проводять ретельний аналіз та оптимізацію вашого сайту, створюють ефективні рекламні кампанії.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					SMM
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Соціальні медіа стали потужним інструментом для взаємодії з аудиторією та побудови бренду. Ми пропонуємо комплексні послуги з SMM, включаючи створення та управління профілями у соціальних мережах, розробку контент-планів, проведення рекламних кампаній та аналіз їх ефективності. 
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Реклама в інтернеті
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Інтернет-реклама є ефективним способом залучення нових клієнтів та підвищення впізнаваності бренду. Ми створюємо та управляємо рекламними кампаніями у різних інтернет-каналах, включаючи Google Ads, Facebook Ads, Instagram Ads та інші. 
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uslinders.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Почніть співпрацю з нами вже сьогодні!
				</Text>
				<Text font="--base">
				Не втрачайте можливість скористатися послугами професійного маркетингового агентства Креативний Маркетинг. Ми готові допомогти вам досягти нових висот.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});